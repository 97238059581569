/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'gatsby';

import TagList from '../TagList';

interface Props {
  postTitle: PostTitle;
}

const PostTitle = ({ postTitle: { title, date, path, tags } }: Props) => {
  return (
    <Link to={`${path}`} style={{ textDecoration: 'none' }}>
      <span css={{ fontSize: 24 }}>{title}</span>
      <span css={{ marginLeft: 10 }}>{date}</span>
      <TagList tags={tags} />
    </Link>
  );
};

export default PostTitle;
