/** @jsx jsx */
import { jsx } from '@emotion/core';

import PostTitle from '../PostTitle';

interface Props {
  postTitles: PostTitle[];
}

const PostTitleList = ({ postTitles }: Props) => {
  return (
    <ul css={{ margin: 0, padding: 0 }}>
      {postTitles.map(postTitle => {
        return (
          <li
            key={postTitle.id}
            css={{
              listStyleType: 'none',
            }}
          >
            <PostTitle postTitle={postTitle} />
          </li>
        );
      })}
    </ul>
  );
};

export default PostTitleList;
