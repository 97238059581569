import React, { useMemo } from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import PostTitleList from '../components/PostTitleList';
import SEO from '../components/SEO';

interface Props {
  data: IndexData;
}

const IndexPage = ({ data }: Props) => {
  const postTitles = useMemo(() => {
    const {
      allMarkdownRemark: { edges },
    } = data;
    return edges.map(edge => {
      const {
        node: {
          id,
          frontmatter: { title, date, path, tags },
        },
      } = edge;

      return Object.assign({
        date,
        id,
        path,
        title,
        tags,
      });
    });
  }, [data]);

  return (
    <Layout>
      <SEO title="Home" />
      Recent Post
      <PostTitleList postTitles={postTitles} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            path
            tags
            title
          }
        }
      }
    }
  }
`;
